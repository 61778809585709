<template>
    <div class="marketing">
        <div class="search-content">
            <a-tree-select style="width: 200px;margin-right:10px" :maxTagCount="1" :treeData="treeDataSchool" treeNodeFilterProp="title"  show-search  allow-clear :value="ParentId" @change="onChangeSchool"  :showCheckedStrategy="SHOW_PARENT" placeholder="请选择部门"/>
            <a-month-picker v-model="Month" placeholder="自定义时间" @change="changeMonth"/>
            <a-button :type="dateType == 1 ? 'primary' : ''" @click="_getDate(1)">本月</a-button>
            <a-button :type="dateType == 0 ? 'primary' : ''" @click="_getDate(0)">上月</a-button>
            <a-button :type="dateType == -1 ? 'primary' : ''" @click="_getDate(-1)">上上月</a-button>
            <!--            <a-button @click="_reset()">重置</a-button>-->
            <!--            <a-button type="primary" @click="_search">搜索</a-button>-->
        </div>
        <div class="content">
            <div class="content-left">
                <div class="tab">
                    <div class="tab-list">
                        <!--                        <i class="iconfont icon-a-zu7680 tab-icon"></i>-->
                        <div class="tab-icon tab-icon-total"></div>
                        <div class="tab-text">
                            <div class="tab-text-num">{{ total.contribution }}</div>
                            <div class="tab-text-title">总贡献</div>
                        </div>
                    </div>
                    <div class="tab-list">
                        <!--                        <i class="iconfont icon-a-zu7680 tab-icon"></i>-->
                        <div class="tab-icon tab-icon-join"></div>
                        <div class="tab-text">
                            <div class="tab-text-num">{{ total.join }}</div>
                            <div class="tab-text-title">参与员工</div>
                        </div>
                    </div>
                    <div class="tab-list">
                        <div class="tab-icon tab-icon-new"></div>
                        <div class="tab-text">
                            <div class="tab-text-num">{{ total.new }}</div>
                            <div class="tab-text-title">新名单</div>
                        </div>
                    </div>
                    <div class="tab-list">
                        <div class="tab-icon tab-icon-exist"></div>
                        <div class="tab-text">
                            <div class="tab-text-num">{{ total.exist }}</div>
                            <div class="tab-text-title">重复名单</div>
                        </div>
                    </div>
                    <div class="tab-list">
                        <div class="tab-icon tab-icon-ty"></div>
                        <div class="tab-text">
                            <div class="tab-text-num">{{ total.ty }}</div>
                            <div class="tab-text-title">体验名单</div>
                        </div>
                    </div>
                    <div class="tab-list">
                        <div class="tab-icon tab-icon-deal"></div>
                        <div class="tab-text">
                            <div class="tab-text-num">{{ total.deal }}</div>
                            <div class="tab-text-title">成交名单</div>
                        </div>
                    </div>
                </div>
                <!--                    图表-->
                <div id="container"></div>
                <!--                排行榜-->
                <div class="ranking">
                    <div class="ranking-list">
                        <div class="ranking-title"  @click="_more('ranking',1)">
                            <div class="ranking-title-icon tab-icon-total"></div>
                            <div class="ranking-title-ranking">
                                贡献排名
                                <span class="ranking-title-num">TOP10</span>
                            </div>
                            <div class="ranking-title-total">{{ total.contribution}}</div>
                        </div>
                        <div class="rank-info">
                            <div class="rank-info-name">名字</div>
                            <div class="rank-info-base">个人信息</div>
                            <div class="rank-info-num">贡献值</div>
                        </div>
                        <div class="rank-item" v-for="(item,index) in contribution">
                            <div class="rank-item-rank" :class="index ==0 || index ==1 || index ==2?'rank-item-rank-active':''">{{
                                    index + 1
                                }}
                            </div>
                            <div class="rank-item-base">
                                <div class="rank-avatar">
                                    <a-avatar :size="38" icon="user" :src="item.Avatar"/>
                                    <div class="rank-you" v-if="item.UserId==userinfo.uid">you</div>
                                </div>

                                <div class="rank-item-base-name">{{ item.UserName }}</div>
                            </div>
                            <div class="rank-item-num">{{ item.Num }}</div>
                        </div>
                    </div>
                    <div class="ranking-list">
                        <div class="ranking-title" @click="_more('ranking',2)">

                            <div class="ranking-title-icon tab-icon-new"></div>
                            <!--                            <i class="iconfont icon-zu7408 ranking-title-icon"></i>-->
                            <div class="ranking-title-ranking">
                                名单排行
                                <span class="ranking-title-num">TOP10</span>
                            </div>
                            <div class="ranking-title-total">{{ studentNum }}</div>
                        </div>
                        <div class="rank-info">
                            <div class="rank-info-name">名字</div>
                            <div class="rank-info-base">个人信息</div>
                            <div class="rank-info-num">名单数量</div>
                        </div>
                        <div class="rank-item" v-for="(item,index) in students">
                            <div class="rank-item-rank" :class="index ==0 || index ==1 || index ==2?'rank-item-rank-active':''">{{
                                    index + 1
                                }}
                            </div>
                            <div class="rank-item-base">
                                <div class="rank-avatar">
                                    <a-avatar :size="38" icon="user" :src="item.Avatar"/>
                                    <div class="rank-you" v-if="item.UserId==userinfo.uid">you</div>
                                </div>
                                <div class="rank-item-base-name">{{ item.UserName }}</div>
                            </div>
                            <div class="rank-item-num">{{ item.Num }}</div>
                        </div>
                    </div>
                    <div class="ranking-list">
                        <div class="ranking-title" @click="_more('ranking',3)">
                            <div class="ranking-title-icon rank-title-zhiyuan"></div>
                            <!--                            <i class="iconfont icon-zu7408 ranking-title-icon"></i>-->
                            <div class="ranking-title-ranking">
                                支援排行
                                <span class="ranking-title-num">TOP10</span>
                            </div>
                            <div class="ranking-title-total">{{ total.support }}</div>
                        </div>
                        <div class="rank-info">
                            <div class="rank-info-name">名字</div>
                            <div class="rank-info-base">个人信息</div>
                            <div class="rank-info-num">支援次数</div>
                        </div>
                        <div class="rank-item" v-for="(item,index) in support">
                            <div class="rank-item-rank" :class="index ==0 || index ==1 || index ==2?'rank-item-rank-active':''">{{
                                    index + 1
                                }}
                            </div>
                            <div class="rank-item-base">
                                <div class="rank-avatar">
                                    <a-avatar :size="38" icon="user" :src="item.Avatar"/>
                                    <div class="rank-you" v-if="item.UserId==userinfo.uid">you</div>
                                </div>
                                <div class="rank-item-base-name">{{ item.UserName }}</div>
                            </div>
                            <div class="rank-item-num">{{ item.Num }}</div>
                        </div>
                    </div>

                </div>
                <!--                名单列表-->
                <div class="list">
                    <div class="list-title">
                        <div class="list-text-title">名单列表</div>
                        <div class="list-title-more" @click="_more('students','students')">查看更多>></div>
                    </div>
                    <div class="list-item" v-for="item in studentlist" @click="_student(item.StudentId,item.StudentName)">
                        <div class="list-item-left">
                            <a-avatar :size="46" icon="user" :src="item.Avatar"/>
                            <div class="list-info">
                                <div class="list-phone">{{ item.StudentName }} {{ item.UserName }}</div>
                                <div class="list-time">{{ item.CreateTime }}</div>
                            </div>
                            <div class="list-status">{{ statusObject[item.Status] }}</div>
                        </div>
                        <div class="list-item-right">
                            {{ item.SchoolName && item.Person ? item.SchoolName + '-' : item.SchoolName }}
                            {{ item.Person }}
                        </div>
                    </div>

                </div>

            </div>
            <div class="content-right">
                <div class="right-title">
                    <div class="right-title-text">产品订单</div>
                    <div class="right-title-more" @click="_more('orderList','orderList')">查看更多>></div>
                </div>
                <div class="right-total">
                    <div class="right-total-info">
                        <div class="right-total-num">{{ product.price }}</div>
                        <div class="right-total-text">销售额</div>
                    </div>
                    <div class="right-total-info">
                        <div class="right-total-num">{{ product.cps_money }}</div>
                        <div class="right-total-text">佣金合计</div>
                    </div>
                </div>
                <div class="right-list" v-if="product" v-for="item in product.list">
                    <a-avatar class="right-list-avatar" :size="40" icon="user" :src="item.avatar"/>
                    <div class="right-list-info">
                        <div class="info-name">{{ item.name }}--{{ item.title }}</div>
                        <div class="info-time">{{ item.created_at }}</div>
                    </div>
                    <div class="right-num">+{{ item.price }}</div>
                </div>
            </div>
        </div>

        <a-drawer
            :title="modelNameArr[modelType]"
            width="1100"
            :closable="false"
            :visible="visible"
            @close="_close"
            :bodyStyle="{background:'#f7f8fa'}"
        >
            <div style="margin-bottom: 60px">
                <marketing_student_list
                    v-if="visible"
                    :modelName="modelName"
                    :modelType="modelType"
                    :Month="Month"
                    @visible="_close"

                ></marketing_student_list>
            </div>
        </a-drawer>

    </div>
</template>

<script>
import G2 from '@antv/g2'
import marketing_student_list from 'pages/Report/marketing_student_list'
import {TreeSelect} from "ant-design-vue";

export default {
    components: {marketing_student_list},
    name: "marketing",
    data() {
        return {
            selectDate: '',
            dateType: -100,
            total: '',
            infoData: '',
            studentlist: [],
            statusObject: {},
            contribution: [],
            students: [],
            support: [],
            product: '',
            fullWidth: '',
            containerWidth: '',
            date: '',
            Month: '',
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            studentNum:0,
            visible:false,
            modelName:'',
            modelNameArr:{'students':'名单列表','orderList':'订单列表',"1":'贡献排名',"2":'名单排行',"3":'支援排行'},
            modelType:'',
            indexArr:[0,1,2],
            treeDataSchool:[],
            SHOW_PARENT: TreeSelect.SHOW_PARENT,
            ParentId: undefined,
        }
    },
    mounted() {
        //
        const that = this
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                that.fullWidth = window.fullWidth
            })()
        }
    },
    watch: {
        /* 监听*/
        fullWidth(val) {
            document.getElementById('container').innerHTML = ' '
            this.containerTable(this.date)
        }
    },
    created() {
        this.$axios.get(8012, {}, res => {
            if (res.data.code == 1) {
                this.treeDataSchool = res.data.data;
            } else {
                this.$message.error(res.data.msg)
            }
        });
        this.get_student_field();
        this._getDate(0);

    },
    methods: {
        onChangeSchool (value) {
            console.log(value);
            this.ParentId = value;
            this.loadData(this.dateType);
        },
        _student(id,StudentName){
            let data = {
                type: 'student',
                name: StudentName,
                id: id
            }
            window.parent.postMessage(data, '*')
        },
        toThousands(num) {
            var num = (num || 0).toString(),
                result = ''

            while (num.length > 3) {
                result = ',' + num.slice(-3) + result
                num = num.slice(0, num.length - 3)
            }

            if (num) {
                result = num + result
            }
            return result
        },
        _more(name,type){
            this.modelName = name;
            this.modelType = type;
            this.visible = true;
        },
        _close(){
            this.modelName = '';
            this.modelType = '';
            this.visible = false;
        },
        
        loadData() {
            this.$message.loading('加载中...', 0);
            let self = this;
            self.total = '';
            this.studentlist = [];
            this.contribution = [];
            this.students = [];
            this.support = []
            this.product = '';
            this.studentNum =0;
            this.$axios.get(129430, {
                month: self.Month,
                ParentId:self.ParentId || '',
            }, res => {
                this.$message.destroy();
                if (res.data.code == 1) {
                    self.total = res.data.data.total;
                    self.studentlist = res.data.data.studentlist;
                    self.contribution = res.data.data.list.contribution;
                    self.students = res.data.data.list.students;
                    self.support = res.data.data.list.support;
                    self.product = res.data.data.product;
                    self.studentNum =  parseInt(self.total.new)+parseInt(self.total.exist);
                    let date = res.data.data.date
                    self.date = date;
                    setTimeout(function () {
                        document.getElementById('container').innerHTML = ' '
                        self.containerTable(date)
                    }, 400)
                }
            })
        },

        //图表

        containerTable(value) {
            let data = value;
            this.containerWidth = document.getElementById('container').clientWidth;
            const chart = new G2.Chart({
                container: 'container',
                autoFit: true,
                height: 420,
                width: this.containerWidth - 40,
                padding: [20, 100, 30, 60]
            })

            chart.source(data)
            chart.scale({

                date: {
                    alias: '日期',
                    type: 'time',
                },
                // new: {
                //     alias: '新名单',
                //     min: 0,
                //     sync: true, // 将 pv 字段数值同 time 字段数值进行同步
                //     nice: true,
                // },
                // exist: {
                //     alias: '重复名单',
                //     min: 0,
                //     sync: true, // 将 pv 字段数值同 time 字段数值进行同步
                //     nice: true,
                //
                // },
                contribution: {
                    alias: '贡献度',
                    min: 0,
                    formatter: (value) => {
                        return this.toThousands(value);
                    },
                    sync: true, // 将 pv 字段数值同 time 字段数值进行同步
                    nice: true,

                },
                achievement: {
                    nice: true
                }
            })
            // chart.axis('date', {
            //     title: {},
            // });
            // chart.axis('new', {
            //     title: {},
            // });
            // chart.axis('contribution', {
            //     title: {},
            // });
            chart.tooltip({
                showCrosshairs: true,
                itemTpl:
                    '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
                shared: true,
            });
            // chart.axis('month', {
            //     label: {
            //         formatter: val => {
            //             return val
            //         }
            //     }
            // })
            // chart
            //     .line()
            //     .position('date*new')
            //     .color('#4FAAEB');
            // chart
            //     .line()
            //     .position('date*exist')
            //     .color('#9AD681')
            // .shape('dash');
            chart
                .line()
                .position('date*contribution')
                .color('#fbd76d')
            // .shape('dash');
            chart.render();
          
        },
        get_student_field() {
            let self = this;
            this.$axios.get(129431, {}, res => {
                if (res.data.code == 1) {
                    self.statusObject = res.data.data;
                }
            })
        },
        _search() {

        },
        changeMonth(date, str) {
            this.Month = str;
            this.loadData();
        },

        _getDate(i) {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + i;
            this.Month = year + '-' + (month < 10 ? '0' + month : month);
            this.dateType = i;
            this.loadData();
        },


    }
}
</script>

<style scoped lang="less">
.search-content {
    display: flex;
    align-items: center;

    button {
        margin: 0 5px;

    }
}

.content {
    display: flex;
    margin-top: 20px;

    .content-left {
        flex: 1;
        margin-right: 15px;

        .tab {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #ffffff;
            margin-bottom: 15px;
            border-radius: 3px;

            .tab-list {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                //justify-content: space-between;
                //width: 25%;
                .tab-icon {
                    width: 50px;
                    height: 50px;
                }

                .tab-icon-total {
                    background: url("https://smart-resource.sikegroup.com/%E6%80%BB%E8%B4%A1%E7%8C%AE.png") no-repeat;
                    background-size: 100% 100%;
                }

                .tab-icon-join {
                    background: url("https://smart-resource.sikegroup.com/%E5%8F%82%E4%B8%8E%E5%91%98%E5%B7%A5.png") no-repeat;
                    background-size: 100% 100%;

                }

                .tab-icon-new {
                    background: url("https://smart-resource.sikegroup.com/%E6%96%B0%E5%90%8D%E5%8D%95.png") no-repeat;
                    background-size: 100% 100%;
                }

                .tab-icon-exist {
                    background: url("https://smart-resource.sikegroup.com/%E9%87%8D%E5%A4%8D%E5%90%8D%E5%8D%95.png") no-repeat;
                    background-size: 100% 100%;
                }

                .tab-icon-ty {
                    background: url("https://smart-resource.sikegroup.com/22222.png") no-repeat;
                    background-size: 100% 100%;
                }

                .tab-icon-deal {
                    background: url("https://smart-resource.sikegroup.com/%E6%88%90%E4%BA%A4.png") no-repeat;
                    background-size: 100% 100%;
                }

                .tab-text {
                    margin-left: 10px;

                    .tab-text-num {
                        color: #242424;
                        font-size: 18px;
                        white-space: nowrap;
                    }

                    .tab-text-title {
                        color: #3F3F3F;
                        font-size: 12px;
                        white-space: nowrap;
                    }
                }

            }
        }

        #container {
            width: 100%;
            background: #ffffff;
            margin-bottom: 15px;
            height: 420px;
            border-radius: 3px;
        }

        .list {
            width: 100%;
            background: #ffffff;
            margin-top: 15px;
            padding-bottom: 15px;
            border-radius: 3px;

            .list-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 20px;
                border-bottom: 1px solid #E5E7EA;

                .list-text-title {
                    color: #303133;
                    font-size: 14px;
                }

                .list-title-more {
                    color: #666666;
                    font-size: 12px;
                    cursor: pointer;
                }
            }

            .list-item {
                display: flex;
                align-items: center;
                padding: 15px 34px 10px 34px;
                border-bottom: 1px solid #E5E7EA;
                cursor: pointer;
                .list-item-left {
                    flex: 1;
                    display: flex;

                    .list-info {
                        margin-left: 15px;
                        margin-right: 5px;

                        .list-phone {
                            color: #242424;
                            font-size: 16px;
                            //margin-bottom: 6px;
                        }

                        .list-time {
                            color: #666666;
                            font-size: 12px;
                        }
                    }

                    .list-status {
                        background: #E6F7FF;
                        border: 1px solid #91D5FF;
                        color: #1890FF;
                        border-radius: 2px;
                        height: 22px;
                        padding: 0px 4px;
                    }

                }

                .list-item-right {
                    width: 35%;
                    color: #666666;
                    font-size: 12px;
                }
            }

            .list-item:hover {
                background: #E5E7EA;
            }
        }

        .ranking {
            display: flex;
            //align-items: center;
            justify-content: space-between;

            .ranking-list {
                width: 32.5%;
                //margin-right: 1.95%;
                padding: 27px 33px 23px 21px;
                background: #ffffff;
                border-radius: 3px;

                .ranking-title {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .ranking-title-icon {
                        width: 45px;
                        height: 45px;
                        margin-right: 20px;
                    }
                    .tab-icon-new {
                        background: url("https://smart-resource.sikegroup.com/%E6%96%B0%E5%90%8D%E5%8D%95.png") no-repeat;
                        background-size: 100% 100%;
                    }
                    .rank-title-zhiyuan {
                        background: url("https://smart-resource.sikegroup.com/%E6%94%AF%E6%8F%B4.png") no-repeat;
                        background-size: 100% 100%;
                    }
                    .tab-icon-total {
                          background: url("https://smart-resource.sikegroup.com/%E6%80%BB%E8%B4%A1%E7%8C%AE.png") no-repeat;
                          background-size: 100% 100%;
                      }

                    .ranking-title-ranking {
                        color: #303133;
                        font-size: 14px;
                        flex: 1;

                        .ranking-title-num {
                            color: #EF5542;
                            font-size: 12px;
                            margin-left: 4px;
                        }
                    }

                    .ranking-title-total {
                        min-width: 60px;
                        color: #1890FF;
                        font-size: 18px;
                    }
                }

                .rank-info {
                    color: #CDCDCD;
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    .rank-info-name {
                        width: 70px;
                    }

                    .rank-info-base {
                        flex: 1;
                    }

                    .rank-info-num {
                        min-width: 60px;
                    }
                }

                .rank-item {
                    display: flex;
                    align-items: center;
                    padding: 8px 0px;
                    margin-bottom: 8px;

                    .rank-item-rank {
                        color: #555555;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background: #F5F5F5;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        margin-right: 14px;
                    }

                    .rank-item-rank-active {
                        background: #FEF2DF;
                        color: #FF9C08;
                    }

                    .rank-item-base {
                        flex: 1;
                        display: flex;
                        align-items: center;

                        .rank-avatar {
                            position: relative;

                            .rank-you {
                                width: 33px;
                                //height: 18px;
                                text-align: center;
                                background: #5175FF;
                                color: #ffffff;
                                position: absolute;
                                bottom: -12px;
                                left: 50%;
                                font-size: 10px;
                                transform: translateX(-50%);
                                border-radius: 2px;
                            }
                        }

                        .rank-item-base-name {
                            color: #303133;
                            font-size: 14px;
                            margin-left: 11px;
                        }
                    }

                    .rank-item-num {
                        color: #303133;
                        font-size: 14px;
                    }
                }
            }

            .ranking-list:last-child {
                margin-right: 0;
            }
        }
    }

    .content-right {
        width: 350px;
        min-height: 100%;
        background-color: #fff;
        padding: 10px 20px 60px 4px;

        .right-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 16px;

            .right-title-text {
                color: #303133;
                font-size: 14px;
                padding-left: 40px;
            }

            .right-title-more {
                color: #666666;
                font-size: 10px;
                cursor: pointer;
            }
        }

        .right-total {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EEF3F7;
            border-top: 1px solid #EEF3F7;

            .right-total-info {
                border-right: 1px solid #EEF3F7;
                width: 50%;
                margin: auto;
                padding: 12px 0px;
                text-align: center;

                .right-total-num {
                    color: #303133;
                    font-size: 14px;
                }

                .right-total-text {
                    color: #303133;
                    font-size: 12px;
                }
            }

            .right-total-info:last-child {
                border-right: 0px;
            }
        }

        .right-list {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid #EEF3F7;
            padding-left: 6px;
            margin-bottom: 14px;
            padding-top: 4px;

            .right-list-avatar {

            }

            .right-list-info {
                flex: 1;
                margin-left: 15px;

                .info-name {
                    color: #242424;
                    font-size: 14px;
                }

                .info-time {
                    color: #666666;
                    font-size: 11px;
                }
            }

            .right-num {
                min-width: 60px;
                text-align: right;
            }
        }
    }
}
</style>