<template>
    <div class="marketing_student_list">
        <div class="ranking">
            <div class="ranking-list" v-for="(tableItem,key) in tableData">

                <div class="rank-info" v-if="modelName=='ranking'">
                    <div class="rank-info-name">名字</div>
                    <div class="rank-info-base">个人信息</div>
                    <div class="rank-info-num">{{modelType==1?'贡献值':modelType==3?'支援次数':'名单数量'}}</div>
                </div>
                <div class="rank-info" v-else >
<!--                    <div class="rank-info-name">名字</div>-->
                    <div class="rank-info-base">基本信息</div>
                    <div class="rank-info-num">{{modelName=='students'?'中心归属':'销售额'}}</div>
                </div>

                <div v-if="modelName=='ranking'">
                    <div class="rank-item" style="cursor: pointer" v-for="(item,index) in tableItem" @click="_student(item.StudentId,item.StudentName)">
                        <div class="rank-item-rank" :class="key ==0 &&  (index ==0 || index ==1 || index ==2)?'rank-item-rank-active':''">{{ (key*10)+(index + 1) }}</div>
                        <div class="rank-item-base">
                            <div class="rank-avatar">
                                <a-avatar :size="38" icon="user" :src="item.Avatar"/>
                                <div class="rank-you" v-if="item.UserId==userinfo.uid">you</div>
                            </div>

                            <div class="rank-item-base-name">{{ item.UserName }}</div>
                        </div>
                        <div class="rank-item-num">{{ item.Num }}</div>
                    </div>
                </div>
                <div v-else>
                    <div class="rank-item" v-for="(item,index) in tableItem" @click="_student(item.StudentId,item.StudentName)">
<!--                        <div class="rank-item-rank" :class="key ==0 &&  (index ==0 || index ==1 || index ==2)?'rank-item-rank-active':''">{{ (key*10)+(index + 1) }}</div>-->
                        <div class="rank-item-base">
                            <div class="rank-avatar">
                                <a-avatar :size="38" icon="user" :src="modelName=='orderList'?item.avatar:item.Avatar"/>
                            </div>
                            <div class="rank-item-base-name">
                                <div>
                                    {{ modelName=='students'?(item.StudentName+'     '+item.UserName):(item.name+'--'+item.title)}}
                                </div>
                                <span style="color: #666666;font-size: 12px">{{modelName=='students'?item.CreateTime:item.created_at}}</span>
                            </div>
                        </div>
                        <div class="rank-item-num">
                            {{modelName=='students'?item.SchoolName:item.price  }}
                            <div v-if="modelName=='students' && item.Person">{{item.Person}}</div>
                        </div>
                    </div>
                </div>

            </div>
          

        </div>
        <div
            :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px'
      }"
        >
            <a-button style="marginRight: 8px" @click="onClose">
                关闭
            </a-button>
<!--            <a-button type="primary" @click="_action()">-->
<!--                确定-->
<!--            </a-button>-->
        </div>
    </div>
</template>

<script>
export default {
name: "marketing_student_list",
    data(){
        return{
            tableData:[],
            url:{'students':129432,'orderList':129433,'ranking':129434},
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
        }
    },
    props:{
        modelName:{
            type:String,
        },
        modelType:{
            type: [String, Number]
        },
        Month:{
            type:String
        }
    },
    created() {
        this.loadData()
    },
    methods:{
        _student(id,StudentName){
            let data = {
                type: 'student',
                name: StudentName,
                id: id
            }
            console.log(id,StudentName)
            if(this.modelName=='students'){
                window.parent.postMessage(data, '*')
            }

        },
        loadData(){
            let self = this;
            this.$message.loading('加载中...',0);
            this.$axios.get(this.url[this.modelName],{
                Month:this.Month,
                Page:1,
                Limit:10000,
                Type:this.modelType
            },res=>{
                this.$message.destroy();
                 if(res.data.code==1){
                     self.tableData = self.group(res.data.data,10);
                 }else{
                     this.$message.error(res.data.msg)
                     self.tableData = [];
                 }
            })
        },
        group(array, subGroupLength) {
            let index = 0;
            let newArray = [];
            while(index < array.length) {
                newArray.push(array.slice(index, index += subGroupLength));
            }
            return newArray;
        },
        onClose() {
            this.tableData = [];
            this.$emit('visible', false)
        },
    }
}
</script>

<style scoped lang="less">
.marketing_student_list{
    background: #f7f8fa;
}
.ranking {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    background: #f7f8fa;
    flex-wrap: wrap;
    padding-bottom: 60px;
    .ranking-list {
        width: 32.5%;
        //margin-right: 1.95%;
        padding: 27px 33px 23px 21px;
        background: #ffffff;
        border-radius: 3px;
         margin-bottom: 20px;
        .ranking-title {
            display: flex;
            align-items: center;

            .ranking-title-icon {
                width: 66px;
                font-size: 24px;
            }

            .ranking-title-ranking {
                color: #303133;
                font-size: 14px;
                flex: 1;

                .ranking-title-num {
                    color: #EF5542;
                    font-size: 12px;
                    margin-left: 4px;
                }
            }

            .ranking-title-total {
                min-width: 60px;
                color: #303133;
                font-size: 18px;
            }
        }

        .rank-info {
            color: #CDCDCD;
            font-size: 14px;
            display: flex;
            align-items: center;

            .rank-info-name {
                width: 70px;
            }

            .rank-info-base {
                flex: 1;
            }

            .rank-info-num {
                min-width: 60px;
            }
        }

        .rank-item {
            display: flex;
            align-items: center;
            padding: 8px 0px;
            margin-bottom: 8px;
            //cursor: pointer;
            .rank-item-rank {
                color: #555555;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #F5F5F5;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                margin-right: 14px;
            }

            .rank-item-rank-active {
                background: #FEF2DF;
                color: #FF9C08;
            }

            .rank-item-base {
                flex: 1;
                display: flex;
                align-items: center;
                .rank-avatar{
                    position: relative;
                    .rank-you{
                        width: 33px;
                        //height: 18px;
                        text-align: center;
                        background: #5175FF;
                        color: #ffffff;
                        position: absolute;
                        bottom: -12px;
                        left: 50%;
                        font-size: 10px;
                        transform: translateX(-50%);
                        border-radius: 2px;
                    }
                }
                .rank-item-base-name {
                    color: #303133;
                    font-size: 14px;
                    margin-left: 11px;
                }
            }

            .rank-item-num {
                color: #303133;
                font-size: 14px;
                text-align: right;
            }
        }
    }

    .ranking-list:last-child {
        margin-right: 0;
    }
}

</style>