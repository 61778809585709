import { render, staticRenderFns } from "./marketing.vue?vue&type=template&id=7b8c9928&scoped=true&"
import script from "./marketing.vue?vue&type=script&lang=js&"
export * from "./marketing.vue?vue&type=script&lang=js&"
import style0 from "./marketing.vue?vue&type=style&index=0&id=7b8c9928&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8c9928",
  null
  
)

export default component.exports